<template>
  <div class="homepage-announcement">
    <header v-if="dataList.length" class="block-header">
      <div class="block-title">
        <i class="ph-megaphone text-primary-100" style="font-size: 28px; transform: scaleX(-1)" />
        <!-- <img src="../assets/megaphone.svg"> -->
        <p>重要公告</p>
      </div>

      <router-link :to="{ name: 'AnnouncementList' }">
        <p class="more-btn">查看更多</p>
      </router-link>
    </header>
    <main v-loading="loading" class="wrapper">
      <NoData v-if="!dataList.length" style="height: 370px" />
      <template v-if="dataList.length">
        <!-- 置頂文章 -->
        <div class="left w-full truncate">
          <router-link v-for="(item, index) in chunkList[0]" :key="item.id" class="w-full" :to="{name: 'AnnouncementDetail', params: {id: item.id}}">
            <div class="announment-item">
              <p class="item-title">
                <img v-if="item.isTop" src="../assets/pin.svg">
                <Tag v-if="isUnread(item.id)" type="danger">New</Tag>
                <span class="truncate">{{ item.title }}</span>
              </p>
              <p class="time">{{ formatDate(item.publishedAt) }}</p>
              <el-divider v-if="index !== chunkList[0].length - 1" class="item-divider" />
            </div>
          </router-link>
        </div>

        <div v-if="chunkList[1]" class="center-divider" />

        <!-- 一般文章 -->
        <div v-if="chunkList[1]" class="right w-full truncate">
          <router-link v-for="(item, index) in chunkList[1]" :key="item.id" class="w-full" :to="{name: 'AnnouncementDetail', params: {id: item.id}}">
            <div class="announment-item">
              <p class="item-title ">
                <img v-if="item.isTop" src="../assets/pin.svg">
                <Tag v-if="isUnread(item.id)" type="danger">New</Tag>
                <span class="truncate">{{ item.title }}</span>
              </p>
              <p class="time">{{ formatDate(item.publishedAt) }}</p>
              <el-divider v-if="index !== chunkList[1].length - 1" class="item-divider" />
            </div>
          </router-link>
        </div>
      </template>
    </main>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import NoData from './NoData.vue'
import { GetAnnouncement } from '@/api/homePage'
import { chunk, includes } from 'lodash'
import { formatDate } from '@/utils/date'
import { useNewsHint } from '@/use/useNewsHint'

export default defineComponent({
  name: 'HomePageAnnouncement',
  components: { NoData },
  setup () {
    const { isUnread } = useNewsHint()

    return { isUnread }
  },
  data: () => ({
    loading: false,
    dataList: [],
    chunkList: [[], []],
  }),
  async mounted () {
    this.loading = true
    await this.getData()
    this.sortData()
    this.loading = false
  },
  methods: {
    formatDate,
    async getData () {
      const [res, err] = await GetAnnouncement({
        start: 0,
        limit: 10,
      })
      if (err) {
        this.$message.error(err.msg || err)
        return
      }
      this.dataList = res
    },
    sortData () {
      if (!this.dataList.length) return
      this.chunkList = chunk(this.dataList, 5)
    },
  },
})
</script>

<style lang="postcss" scoped>
.more-btn {
  @apply underline text-gray-80;
}

.wrapper {
  @apply bg-white rounded-[10px] p-[20px] mt-[12px];
  @apply flex justify-evenly min-h-[370px];
  box-shadow: 0px 1px 9px rgba(1, 10, 38, 0.1);
}

.block-header {
  @apply flex items-center relative;
  @apply text-lg leading-[21.6px] tracking-[1px] font-medium text-primary-100 justify-between;
}

.block-title {
  @apply flex items-center text-primary-100 gap-[12px] font-medium;
}
.left,
.right {
  @apply flex flex-col gap-[12px];
}
.time {
  @apply text-sm text-gray-60 mt-[12px];
}

.item-title {
  @apply text-gray-100 flex items-center gap-[12px];
}

.item-divider {
  @apply mt-[12px] mb-0;
}

.center-divider {
  @apply h-auto w-[2px] bg-gray-50 mx-[20px];
}

.announment-item {
  @apply cursor-pointer;
}
</style>
