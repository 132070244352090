<template>
  <div class="line-notify-quota">
    <p>目前 Line 訊息使用：{{ quota.usage }} / 本月Line訊息總額度：{{ quota.limit }} </p>
    <ProgressBar :percentage="quotaPercentege" />
    <LineNotifyQuotaWarning
      v-if="showQuotaWarning"
      @close="showQuotaWarning = false"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import ProgressBar from '@/components/Progress/ProgressBar.vue'
import { useLineNotify } from '@/use/useLineNotify'
import LineNotifyQuotaWarning from '@/components/Dialog/LineNotifyQuotaWarning.vue'

export default defineComponent({
  name: 'LineNotifyQuota',
  components: { ProgressBar, LineNotifyQuotaWarning },
  setup (props) {
    const { quota, quotaPercentege, checkLineQuota, showQuotaWarning, openWarningModal } = useLineNotify()

    onMounted(async () => {
      await checkLineQuota()
      openWarningModal()
    })
    return { quota, quotaPercentege, showQuotaWarning }
  },
})
</script>

<style lang="postcss" scoped>

</style>
