import { reactive, computed, ref } from 'vue'
import { CheckLineQuota } from '@/api/line'
import store from '@/store'
import ls from 'local-storage'
import { get } from 'lodash'

export const useLineNotify = (warningLimit = 85) => {
  const shopId = computed(() => store.getters.shop)
  const showQuotaWarning = ref(false)
  const quota = reactive({
    limit: 0,
    usage: 0,
  })
  const quotaPercentege = computed(() => {
    if (quota.usage === 0 && quota.limit === 0) return 0
    return Math.floor(quota.usage / quota.limit * 100)
  })

  const checkLineQuota = async () => {
    const [res, err] = await CheckLineQuota({ shopId: shopId.value })
    if (err) return window.$message.error(err)
    quota.limit = res.limit
    quota.usage = res.usage
  }

  const openWarningModal = () => {
    // 判斷是否不再顯示
    const dontShowAgainConfig = ls.get('dontShowAgain') || {}
    if (get(dontShowAgainConfig, 'lineNotifyQuotaWarning')) return
    console.log(quotaPercentege.value, warningLimit)
    if (Number(quotaPercentege.value) < Number(warningLimit)) return
    showQuotaWarning.value = true
  }

  return { quota, quotaPercentege, checkLineQuota, showQuotaWarning, openWarningModal }
}
