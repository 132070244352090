<template>
  <main class="home-page">
    <HeadBar />
    <el-divider />
    <div class="layout">
      <LineNotifyQuota />
      <Banner />
      <Announcement />
      <HelpCenter />
    </div>
  </main>
</template>

<script>
import HeadBar from './components/HeadBar.vue'
import Banner from './components/Banner.vue'
import Announcement from './components/Announcement.vue'
import HelpCenter from './components/HelpCenter.vue'
import LineNotifyQuota from '@/components/Progress/LineNotifyQuota.vue'

export default {
  name: 'HomePage',
  components: { HeadBar, Banner, Announcement, HelpCenter, LineNotifyQuota },
  mounted () {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },
}
</script>

<style lang="postcss" scoped>
.layout {
  @apply flex flex-col gap-[16px];
}
</style>
