<template>
  <BaseDialog
    :show="true"
    width="566px"
    title="LINE 訊息額度即將達到上限"
    btn1="前往操作"
    @confirm="onConfirm"
    @cancel="$emit('close')"
    @close="$emit('close')"
  >
    <div>
      <p>
        提醒您，LINE 推播訊息已用盡 85％，若需提升您的訊息推播總額，請繼續前往LINE 官方帳號頁面操作。
      </p>
      <br>
      <p>請注意：若訊息數達上限，可能會導致推播訊息失敗。</p>

      <el-checkbox style="margin-top: 22.5px" label="不再顯示此訊息" @change="onChecked" />
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { defineComponent, onMounted, ref } from 'vue'
import ls from 'local-storage'
import { bobLineAt } from '@/config/line'

export default defineComponent({
  name: 'LineNotifyQuotaWarning',
  components: { BaseDialog },
  emits: ['close'],
  setup (props, { emit }) {
    const dontShowAgainConfig = ref({})
    const onChecked = (checked) => {
      if (checked) dontShowAgainConfig.value.lineNotifyQuotaWarning = true
      if (!checked) dontShowAgainConfig.value.lineNotifyQuotaWarning = false
    }

    const onConfirm = () => {
      ls.set('dontShowAgain', dontShowAgainConfig.value)

      const link = document.createElement('a')
      link.href = bobLineAt
      link.target = '_blank'
      link.click()
      link.remove()
      emit('close')
    }

    onMounted(() => {
      dontShowAgainConfig.value = ls.get('dontShowAgain') || {}
    })

    return { onChecked, onConfirm }
  },
})
</script>

<style lang="postcss" scoped>

</style>
