import { admin2 } from './instance'

export const GetLineLoginConfig = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/lineLoginConfig`,
  })
}

export const GetLineLoginConfigRedirectUrl = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/lineLoginConfig/redirectUrl`,
  })
}

export const UpdateLineLoginConfig = async ({
  shopId,
  channelId,
  channelSecret,
  enableLoginBotLink,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/lineLoginConfig`,
    data: {
      channelId,
      channelSecret,
      enableLoginBotLink,
    },
  })
}

export const GetLineBindingConfig = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/lineBindingSetting`,
  })
}

export const UpdateLineBindingConfig = async ({
  shopId,
  enabledJoinFriend,
  requiredJoinFriend,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/lineBindingSetting`,
    data: {
      enabledJoinFriend,
      requiredJoinFriend,
    },
  })
}

export const CheckLineQuota = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/lineConfig/checkQuota`,
  })
}
