<template>
  <main class="homepage-banner">
    <NoData v-if="!banners.length" />
    <el-carousel v-if="banners.length" height="254px" arrow="never">
      <el-carousel-item v-for="item in banners" :key="item.id">
        <!-- <h3 class="small">{{ item }}</h3> -->
        <a :href="item.link" target="_blank" rel="noopener noreferrer">
          <div class="flex items-center justify-center h-full">
            <img :src="imgSrc(1200, item.Image)" class="h-full object-contain" />
          </div>
        </a>
      </el-carousel-item>
    </el-carousel>
  </main>
</template>

<script>
import NoData from './NoData.vue'
import { GetBanner } from '@/api/homePage'
import { getDataFromApi } from '@/utils/api'
import { imgSrc } from '@/utils/helper'

export default {
  name: 'HomePageBanner',
  components: { NoData },
  data: () => ({
    banners: [],
  }),
  async mounted() {
    await this.refresh()
  },
  methods: {
    imgSrc,
    async refresh() {
      await getDataFromApi(GetBanner, {
        payload: { type: 'shop_home' },
        handler: (res) => {
          this.banners = res
        },
      })
    },
  },
}
</script>

<style lang="postcss" scoped>
.homepage-banner {
  @apply bg-white rounded-[6px] h-[254px];
  box-shadow: 0px 1px 9px rgba(1, 10, 38, 0.1);
}

::v-deep .el-carousel__indicator--horizontal {
  @apply px-[6px];
}

::v-deep .el-carousel__indicators button {
  @apply w-[12px] h-[12px] rounded-full;
}
</style>
