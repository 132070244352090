<template>
  <div class="flex justify-center items-center h-full text-gray-60 select-none">
    <p>籌備中，敬請期待！</p>
  </div>
</template>
<script>
export default {
  name: 'NoData',
}
</script>
